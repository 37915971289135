import cn from 'classnames'
import { ButtonHTMLAttributes, FC, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import classNames from 'classnames'

export const themeClassNames = {
  danger: 'btn btn-danger',
  outlinePrimary: 'btn btn-outline-primary',
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  tertiary: 'btn-tertiary',
  text: 'btn-link fw-semibold',
}

export const sizeClassNames = {
  default: '',
  'extra-small': 'btn-xs',
  large: 'btn-lg',
  small: 'btn-sm',
}

export type ButtonType = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick'
> & {
  iconRight?: string
  iconLeft?: string
  icon?: boolean
  shortcut?: string
  hideShortcut?: boolean
  shortcutLeft?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement> | undefined) => void
  theme?: keyof typeof themeClassNames
  size?: keyof typeof sizeClassNames
}

export const Button: FC<ButtonType> = ({
  children,
  className,
  hideShortcut,
  icon,
  iconLeft,
  iconRight,
  onClick,
  shortcut,
  shortcutLeft,
  size = 'default',
  theme = 'primary',
  ...rest
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!rest.disabled) {
      buttonRef.current?.blur()
      onClick?.(e)
    }
  }
  useHotkeys(shortcut || '', handleClick as any, [onClick])
  const shortcutEl = shortcut && !hideShortcut && (
    <i className={classNames('key__button', { 'me-2': !!shortcutLeft })}>
      {shortcut === 'Enter' ? (
        '⏎'
      ) : shortcut === 'ArrowLeft' ? (
        <span className='fa fa-arrow-left' />
      ) : shortcut === 'ArrowRight' ? (
        <span className='fa fa-arrow-right' />
      ) : (
        shortcut
      )}
    </i>
  )
  return (
    <button
      type='button'
      ref={buttonRef}
      {...rest}
      onClick={handleClick}
      className={cn(
        'btn',
        { 'btn-icon': !!icon, 'flex-row lh-1 align-items-center': !!shortcut },
        className,
        themeClassNames[theme],
        sizeClassNames[size],
      )}
    >
      {iconLeft && <i className={cn('icon icon--left', 'me-2', iconLeft)} />}
      {!!shortcutLeft && shortcutEl}
      {children}
      {iconRight && <i className={cn('icon icon--right', 'ms-2', iconRight)} />}
      {!shortcutLeft && shortcutEl}
    </button>
  )
}

Button.displayName = 'Button'

export default Button
