import { FC, ReactNode } from 'react'
import classNames from 'classnames'

type TableContainerType = {
  children: ReactNode
  className?: string
}

const ContentContainer: FC<TableContainerType> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        'px-5 py-4 bg-white shadow-2 rounded-lg',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default ContentContainer
